export default [
    { key: 'beef', text: 'Boeuf' },
    { key: 'chicken', text: 'Poulet' },
    { key: 'chicken-breast', text: 'Blanc de poulet' },
    { key: 'chopped-steak', text: 'Steak haché' },
    { key: 'egg', text: 'Oeuf' },
    { key: 'fish-food', text: 'Poisson' },
    { key: 'ham', text: 'Jambon' },
    { key: 'lamb', text: 'Agneau' },
    { key: 'mutton', text: 'Mouton' },
    { key: 'pig', text: 'Cochon' },
    { key: 'shrimp', text: 'Crevette' },
    { key: 'turkey', text: 'Dinde' },
    { key: 'turkey-breast', text: 'Blanc de dinde' },
    { key: 'veal', text: 'Veau' }
];
