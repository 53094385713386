import { LUNCH_ORDERS } from '../const';
import compareDate from './index';

/**
 * Check if a meal is correctly defined
 * @param {Meal} meal
 * @return {boolean}
 */
export function isMealComplete(meal) {
    return meal.food.length > 0 && meal.quantity !== '' && meal.quantity !== '0';
}

/**
 * Sort meals function
 * @param {Meal} mealA
 * @param {Meal} mealB
 * @return {number}
 */
export function sortMeals(mealA, mealB) {
    const comparedDates = compareDate(mealA.date, mealB.date);
    if (comparedDates !== 0) {
        return -1 * comparedDates;
    }

    if (LUNCH_ORDERS[mealA.mealMoment] > LUNCH_ORDERS[mealB.mealMoment]) {
        return -1;
    }
    if (LUNCH_ORDERS[mealA.mealMoment] < LUNCH_ORDERS[mealB.mealMoment]) {
        return 1;
    }

    return mealA.date >= mealB.date ? 1 : -1;
}
