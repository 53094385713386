import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DisplayDate from '../DisplayDate/DisplayDate';
import Icon from '../Icon/Icon';
import { MAPPED_INGREDIENTS } from '../../const';
import PleasureRating from '../FormElements/PleasureRating/PleasureRating';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import { removeMeal } from '../../actions/meal';
import { IconButton } from '@material-ui/core';
import { getAuthIsLoggedIn } from '../../selectors/auth';
import InfoIcon from '@material-ui/icons/Info';

function MealHistoryLine(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {
        date,
        food,
        mealMoment,
        quantity,
        quantityUnit,
        satisfaction,
        informationNote
    } = props.meal;
    const { isLoggedIn } = props;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRemove = () => {
        props.removeMeal(props.meal);
        handleClose();
    };

    return (
        <React.Fragment>
            <TableRow>
                <TableCell align="right">
                    <div className="row">
                        <div className="col justify-center">
                            <Icon type={mealMoment} size="md" />
                        </div>
                        <div className="col justify-center ml-s">
                            <div className="row">
                                <div className="col justify-center">
                                    <DisplayDate date={date} />
                                </div>
                                {informationNote ? (
                                    <div className="col justify-center">
                                        <Tooltip title={informationNote}>
                                            <InfoIcon className="ml-s" />
                                        </Tooltip>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </TableCell>
                <TableCell align="right">
                    <div className="row">
                        {food.map((f) => (
                            <Icon
                                key={f}
                                size="md"
                                type={MAPPED_INGREDIENTS[f].key}
                                label={MAPPED_INGREDIENTS[f].text}
                            />
                        ))}
                    </div>
                </TableCell>
                <TableCell align="right">
                    {quantity} {quantityUnit}
                </TableCell>
                <TableCell align="right">
                    <PleasureRating value={satisfaction} readOnly={true} labelled={false} />
                </TableCell>
                {isLoggedIn ? (
                    <TableCell>
                        <IconButton
                            aria-controls="simple-menu"
                            style={{ float: 'right' }}
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleRemove}>Supprimer</MenuItem>
                        </Menu>
                    </TableCell>
                ) : null}
            </TableRow>
        </React.Fragment>
    );
}

MealHistoryLine.propTypes = {
    meal: PropTypes.object.isRequired,
    removeMeal: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = (store) => ({
    isLoggedIn: getAuthIsLoggedIn(store)
});

const mapDispatchToProps = (dispatch) => ({
    removeMeal: (meal) => dispatch(removeMeal(meal))
});

export default connect(mapStateToProps, mapDispatchToProps)(MealHistoryLine);
