export const CLEAR_MEALS = 'CLEAR_MEALS';

export const MEAL_LOADING = 'MEAL_LOADING';
export const MEAL_END_LOADING = 'MEAL_END_LOADING';

export const MEAL_FETCH_SUCCESS = 'MEAL_FETCH_SUCCESS';
export const MEAL_FETCH_FAIL = 'MEAL_FETCH_FAIL';

export const MEAL_CREATE_SUCCESS = 'MEAL_CREATE_SUCCESS';
export const MEAL_CREATE_FAIL = 'MEAL_CREATE_FAIL';

export const MEALS_CREATE_SUCCESS = 'MEALS_CREATE_SUCCESS';
export const MEALS_CREATE_FAIL = 'MEALS_CREATE_FAIL';

export const MEAL_UPDATE_SUCCESS = 'MEAL_UPDATE_SUCCESS';
export const MEAL_UPDATE_FAIL = 'MEAL_UPDATE_FAIL';

export const MEAL_DELETE_SUCCESS = 'MEAL_DELETE_SUCCESS';
export const MEAL_DELETE_FAIL = 'MEAL_DELETE_FAIL';
