import {
    MEAL_CREATE_FAIL,
    MEAL_CREATE_SUCCESS,
    MEAL_END_LOADING,
    MEAL_LOADING,
    MEAL_FETCH_SUCCESS,
    MEAL_FETCH_FAIL,
    CLEAR_MEALS,
    MEAL_DELETE_SUCCESS,
    MEAL_DELETE_FAIL,
    MEALS_CREATE_SUCCESS,
    MEALS_CREATE_FAIL
} from '../types/meal';

const initialState = {
    isLoading: false,
    error: null,
    hasError: false,
    meals: [],
    changes: 0,
    lastEventCode: null,
    lastEventValue: null
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case MEAL_LOADING: {
            return {
                ...state,
                isLoading: true,
                hasError: false,
                error: null
            };
        }
        case MEAL_CREATE_SUCCESS: {
            const { meal } = payload;
            const nextMeals = state.meals;
            nextMeals.unshift(meal);
            return {
                ...state,
                hasError: false,
                meals: [...nextMeals],
                changes: state.changes + 1,
                error: null
            };
        }
        case MEALS_CREATE_SUCCESS: {
            const { meals } = payload;
            const nextMeals = state.meals;
            nextMeals.unshift(...meals);
            return {
                ...state,
                hasError: false,
                meals: [...nextMeals],
                changes: state.changes + 1,
                error: null
            };
        }
        case MEAL_END_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case MEAL_FETCH_SUCCESS: {
            return {
                ...state,
                meals: state.meals.concat(payload.meals),
                changes: state.changes + 1,
                hasError: false,
                error: null
            };
        }
        case MEAL_CREATE_FAIL:
        case MEALS_CREATE_FAIL:
        case MEAL_DELETE_FAIL:
        case MEAL_FETCH_FAIL: {
            return {
                ...state,
                hasError: true,
                error: payload.error
            };
        }
        case MEAL_DELETE_SUCCESS: {
            return {
                ...state,
                meals: state.meals.filter((meal) => meal.docId !== payload.docId),
                changes: state.changes + 1,
                hasError: false,
                error: null
            };
        }
        case CLEAR_MEALS: {
            return {
                ...state,
                meals: []
            };
        }
        default: {
            return state;
        }
    }
};
