import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function HideTriedIngredientsSwitch(props) {
    const { checked, onChange } = props;
    return (
        <React.Fragment>
            <FormControlLabel
                control={
                    <Switch
                        checked={checked}
                        onChange={(event) => onChange(event.target.checked)}
                        name="show-tried"
                        color="primary"
                    />
                }
                label="Masquer les aliments essayés"
            />
        </React.Fragment>
    );
}

HideTriedIngredientsSwitch.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
};
