export default [
    { key: 'artichoke', text: 'Artichaut', months: [6, 7, 8, 9] },
    { key: 'asparagus', text: 'Asperge', months: [4, 5, 6] },
    { key: 'avocado', text: 'Avocat', months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
    { key: 'beet', text: 'Betterave', months: [5, 6, 7, 8, 9, 10] },
    { key: 'bell-pepper', text: 'Poivron', months: [6, 7, 8, 9, 10] },
    { key: 'broccoli', text: 'Brocoli', months: [6, 7, 8, 9, 10] },
    { key: 'brussels-sprout', text: 'Choux de Bruxelle', months: [1, 8, 9, 10, 11, 12] },
    { key: 'cabbage', text: 'Chou', months: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12] },
    { key: 'carrot', text: 'Carotte', months: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12] },
    { key: 'cauliflower', text: 'Chou-fleur', months: [5, 6, 7, 8, 9, 10] },
    { key: 'celery', text: 'Céleri', months: [6, 7, 8, 9, 10, 11] },
    { key: 'chard', text: 'Blette', months: [4, 5, 6, 7, 8, 9, 10] },
    { key: 'corn', text: 'Maïs', months: [7, 8, 9, 10] },
    { key: 'cucumber', text: 'Concombre', months: [5, 6, 7, 8, 9] },
    { key: 'eggplant', text: 'Aubergine', months: [5, 6, 7, 8, 9] },
    { key: 'fennel', text: 'Fenouil', months: [5, 6, 7, 8, 9, 10, 11] },
    { key: 'garlic', text: 'Aïl', months: [4, 5, 6, 7, 8] },
    { key: 'gourd', text: 'Potiron', months: [1, 2, 9, 10, 11, 12] },
    { key: 'leek', text: 'Poireau', months: [1, 2, 3, 9, 10, 11, 12] },
    {
        key: 'mix-vegetable',
        text: 'Petits légumes',
        months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    },
    { key: 'mushroom', text: 'Champignon', months: [1, 2, 3, 4, 5, 9, 10, 11] },
    { key: 'olive', text: 'Olive', months: [1, 9, 10, 11, 12] },
    { key: 'onion', text: 'Oignon', months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
    { key: 'peas', text: 'Petit Pois', months: [6, 7] },
    { key: 'parsnip', text: 'Panais', months: [9, 10, 11, 12] },
    { key: 'potato', text: 'Pomme de terre', months: [1, 2, 5, 6, 7, 8, 9, 10, 11, 12] },
    { key: 'pumpkin', text: 'Citrouille', months: [1, 2, 9, 10, 11, 12] },
    { key: 'squash', text: 'Courge butternut', months: [8, 9, 10, 11, 12] },
    { key: 'radish', text: 'Radis', months: [4, 5, 6, 7] },
    { key: 'soy', text: 'Haricots verts', months: [5, 6, 7, 8, 9, 10] },
    { key: 'spinach', text: 'Epinard', months: [4, 5, 6, 7, 8, 9, 10, 11] },
    { key: 'sweet-potato', text: 'Patate douce', months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
    { key: 'tomato', text: 'Tomate', months: [5, 6, 7, 8, 9, 10] },
    { key: 'turnip', text: 'Navet', months: [6, 7, 8, 9] },
    { key: 'white-beans', text: 'Haricot blanc', months: [7, 8, 9, 10] },
    { key: 'zucchini', text: 'Courgette', months: [6, 7, 8, 9] }
];
