import { version } from '../../package.json';
import { WINDOW_RESIZE } from '../const';

const initialState = {
    version,
    width: 0,
    height: 0
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    if (type === WINDOW_RESIZE) {
        return {
            ...state,
            ...payload
        };
    }
    return state;
}
