import React from 'react';
import { Typography } from '@material-ui/core';

export default function Home() {
    return (
        <React.Fragment>
            <Typography variant={'h2'}>Bienvenue sur les repas de Lucas!</Typography>
            <div className="mt-l" />
            <Typography variant={'body1'}>
                Ici sont listé les ingrédients composant les repas de Lucas afin d&apos;avoir un
                suivi complet de ce qu&apos;il mange.
            </Typography>
        </React.Fragment>
    );
}
