import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

const config = {
    apiKey: 'AIzaSyCzZchsIQrFyrxUsjHDpPOdgL58ULhJXGs',
    authDomain: 'morgandenis-baby.firebaseapp.com',
    databaseURL: 'https://morgandenis-baby.firebaseio.com',
    projectId: 'morgandenis-baby',
    storageBucket: 'morgandenis-baby.appspot.com',
    messagingSenderId: '1056373368662',
    appId: '1:1056373368662:web:21c5bd38f42ca545f558f7',
    measurementId: 'G-G63VPGNSJ1'
};
const isLocalhost = window.location.hostname === 'localhost';
if (isLocalhost) {
    console.warn('### Dev table in use ###');
}
const TABLES = {
    meals: isLocalhost ? 'meals-dev' : 'meals',
    foodAggregates: isLocalhost ? 'foodAggregates-dev' : 'foodAggregates'
};
// Initialize Firebase
const app = firebase.initializeApp(config);
app.analytics();
export const meals = app.firestore().collection(TABLES.meals);
export const foodAggregates = app.firestore().collection(TABLES.foodAggregates);
export const auth = app.auth();
export const db = app.firestore();
