import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { INGREDIENTS } from '../../const';
import Icon from '../Icon/Icon';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

export default function FoodSelector(props) {
    const [foodList, setFoodList] = useState(props.value || []);

    function handleChange(e, newValue) {
        setFoodList(newValue);
        props.onChange(newValue);
    }

    function handleDeletion(key) {
        setFoodList(foodList.filter((food) => food.key !== key));
    }

    return (
        <Autocomplete
            multiple
            value={foodList}
            style={{ width: '100%' }}
            options={INGREDIENTS.ALL}
            autoHighlight
            getOptionLabel={(option) => option.text || ''}
            renderOption={(option) => (
                <React.Fragment>
                    <Icon type={option.key} />
                    <span style={{ marginLeft: '0.5em' }}>{option.text}</span>
                </React.Fragment>
            )}
            renderTags={(values) => {
                return (
                    <React.Fragment>
                        {values.map((value) => (
                            <Chip
                                style={{ margin: '0.2em', paddingLeft: '0.75em' }}
                                key={value.key}
                                onDelete={() => handleDeletion(value.key)}
                                icon={<Icon type={value.key} />}
                                label={value.text}
                            />
                        ))}
                    </React.Fragment>
                );
            }}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Choisissez un aliment"
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password' // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}

FoodSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array
};
