import React from 'react';
import PropTypes from 'prop-types';
import compareDate from '../../utils';
import Typography from '@material-ui/core/Typography';

export default function CheckMeals(props) {
    const { latestMeal } = props;
    if (latestMeal) {
        if (compareDate(latestMeal.date) !== 0) {
            return (
                <React.Fragment>
                    <Typography variant={'h6'}>
                        <span role="img" aria-label="warning">
                            ⚠️
                        </span>{' '}
                        Il semble que tous les repas n&apos;aient pas été saisis.{' '}
                        <span role="img" aria-label="scream">
                            😱
                        </span>
                    </Typography>
                </React.Fragment>
            );
        }
    }
    return null;
}

CheckMeals.propTypes = {
    latestMeal: PropTypes.object
};
