import store from '../store';
import { windowResize } from '../actions/app';

function dispatchResize() {
    store.dispatch(
        windowResize(document.documentElement.clientHeight, document.documentElement.clientWidth)
    );
}

function resizeEvent() {
    window.addEventListener('resize', () => {
        dispatchResize();
    });
}

export default () => {
    resizeEvent();
    dispatchResize();
};
