import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Icon from '../Icon/Icon';
import FormLabel from '@material-ui/core/FormLabel';
import { Typography } from '@material-ui/core';
import { LUNCH_TIMES } from '../../const';

function MealMomentLabel(props) {
    return (
        <React.Fragment>
            <div className="row">
                <div className="col justify-center">
                    <Icon type={props.value} size={'md'} />
                </div>
                <div className="col justify-center">
                    <Typography variant={'body1'} style={{ marginLeft: '0.25em' }}>
                        {props.label}
                    </Typography>
                </div>
            </div>
        </React.Fragment>
    );
}

MealMomentLabel.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

function MealMomentRadio(props) {
    return (
        <React.Fragment>
            <FormControlLabel
                value={props.value}
                control={<Radio />}
                label={<MealMomentLabel {...props} />}
            />
        </React.Fragment>
    );
}

MealMomentRadio.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

const MEAL_MOMENTS = [
    { value: LUNCH_TIMES.breakfast, label: 'Petit déjeuner' },
    { value: LUNCH_TIMES.lunch, label: 'Déjeuner' },
    { value: LUNCH_TIMES.teatime, label: 'Gouter' },
    { value: LUNCH_TIMES.dinner, label: 'Dîner' }
];

export default function MealMomentSelector(props) {
    const [value, setValue] = React.useState(props.value || 'lunch');

    const handleChange = (event) => {
        const { value } = event.target;
        setValue(value);
        if (props.onChange) {
            props.onChange(value);
        }
    };

    return (
        <React.Fragment>
            <FormControl component="fieldset">
                <FormLabel component="legend">Moment du repas</FormLabel>
                <RadioGroup
                    row
                    aria-label="mealMoment"
                    name="mealMoment"
                    value={value}
                    onChange={handleChange}
                >
                    {MEAL_MOMENTS.map((mealMoment) => (
                        <MealMomentRadio key={mealMoment.value} {...mealMoment} />
                    ))}
                </RadioGroup>
            </FormControl>
        </React.Fragment>
    );
}

MealMomentSelector.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};
