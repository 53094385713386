import { EVENT_AUTH } from '../types/event';

const initialState = {
    count: 0,
    lastEventCode: null,
    lastEventType: null,
    lastEventValue: null
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case EVENT_AUTH: {
            return {
                ...state,
                lastCode: payload.code,
                lastType: payload.type,
                lastValue: payload.value,
                count: state.count + 1
            };
        }
        default:
            return state;
    }
};
