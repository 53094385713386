import React, { useEffect, useState } from 'react';
import { INGREDIENTS } from '../../const';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import sortByName from '../../utils/food';
import { getFoodAggregateIsLoading, getMapOfTriedFood } from '../../selectors/foodAggregate';
import { getAll } from '../../actions/foodAggregate';
import ListOfIngredients from '../../components/ListOfIngredients/ListOfIngredients';
import SearchIngredient from '../../components/FormElements/SearchIngredient';
import HideTriedIngredientsSwitch from '../../components/FormElements/HideTriedIngredientsSwitch';

const INGREDIENTS_TO_SHOW = [
    { food: INGREDIENTS.FRUITS.sort(sortByName), title: 'Fruits' },
    { food: INGREDIENTS.VEGETABLES.sort(sortByName), title: 'Légumes' },
    { food: INGREDIENTS.MEET.sort(sortByName), title: 'Protéines' },
    { food: INGREDIENTS.OTHERS.sort(sortByName), title: 'Divers' }
];

function FoodList(props) {
    const { fetchAll, triedFood } = props;
    const [hideTried, setHideTried] = useState(false);
    const [filterFood, setFilterFood] = useState('');

    useEffect(() => {
        fetchAll();
    }, [fetchAll]);

    return (
        <React.Fragment>
            <div className="row mt-s space-evenly wrap">
                <div className="col justify-center">
                    <HideTriedIngredientsSwitch checked={hideTried} onChange={setHideTried} />
                </div>
                <div className="col justify-center">
                    <SearchIngredient value={filterFood} onChange={setFilterFood} />
                </div>
            </div>
            <div className="row">
                <div className="col full">
                    {INGREDIENTS_TO_SHOW.map(({ food, title }, i) => (
                        <Paper key={i} className="mt-m p-s">
                            <div className="row">
                                <Typography variant="h5">{title}</Typography>
                            </div>
                            <div className="row wrap space-evenly mt-s">
                                <ListOfIngredients
                                    ingredients={food.map((ingredient) => ({
                                        ...ingredient,
                                        tried: triedFood[ingredient.key]
                                    }))}
                                    showTried={!hideTried}
                                    filter={filterFood}
                                />
                            </div>
                        </Paper>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}

FoodList.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    triedFood: PropTypes.object.isRequired,
    fetchAll: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isLoading: getFoodAggregateIsLoading(state),
    triedFood: getMapOfTriedFood(state)
});

const mapDispatchToProps = (dispatch) => ({
    fetchAll: () => dispatch(getAll())
});

export default connect(mapStateToProps, mapDispatchToProps)(FoodList);
