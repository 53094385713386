export const getFoodAggregateState = (store) => store.foodAggregate;
export const getFoodAggregateIsLoading = (store) =>
    getFoodAggregateState(store) ? getFoodAggregateState(store).isLoading : false;
export const getAllFoodAggregates = (store) =>
    getFoodAggregateState(store) ? getFoodAggregateState(store).foodAggregates : [];
export const getMapOfTriedFood = (store) =>
    getFoodAggregateState(store)
        ? getAllFoodAggregates(store).reduce((acc, aggregate) => {
              return { ...acc, [aggregate.food]: true };
          }, {})
        : {};
