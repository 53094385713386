import { meals } from '../firebase';
import {
    CLEAR_MEALS,
    MEAL_CREATE_FAIL,
    MEAL_CREATE_SUCCESS,
    MEALS_CREATE_FAIL,
    MEALS_CREATE_SUCCESS,
    MEAL_DELETE_FAIL,
    MEAL_DELETE_SUCCESS,
    MEAL_END_LOADING,
    MEAL_FETCH_FAIL,
    MEAL_FETCH_SUCCESS,
    MEAL_LOADING
} from '../types/meal';
import { EVENT_MEAL } from '../types/event';
import { v4 as uuid } from 'uuid';
import {
    addBatchInFoodAggregate,
    addInFoodAggregate,
    removeFromFoodAggregate
} from './foodAggregate';

/**
 * @typedef Meal
 * @property {string[]} food list of food contained in the meal
 * @property {string} mealMoment
 * @property {string} date
 * @property {number} satisfaction
 * @property {string} quantityUnit
 * @property {string} quantity
 * @property {string} [informationNote]
 * @property {any} [createdAt]
 * @property {any} [updatedAt]
 * @property {string} [docId]
 */

/**
 *
 * @param {Meal} meal
 * @returns {function}
 */
export const createMeal = (meal) => async (dispatch) => {
    dispatch({ type: MEAL_LOADING });
    const meta = { createdAt: new Date(), updatedAt: new Date(), docId: uuid() };
    const mealToSave = { ...meal, ...meta };
    try {
        await meals.doc(mealToSave.docId).set(mealToSave);
        dispatch({ type: MEAL_CREATE_SUCCESS, payload: { meal: mealToSave, isNew: true } });
        dispatch({
            type: EVENT_MEAL,
            payload: { type: 'meal', code: 'created', value: mealToSave }
        });
        dispatch(addInFoodAggregate(mealToSave));
    } catch (error) {
        dispatch({ type: MEAL_CREATE_FAIL, payload: { error } });
    }
    dispatch({ type: MEAL_END_LOADING });
};

/**
 *
 * @param {Meal[]} mealList
 * @return {function(*): Promise<void>}
 */
export const createDayMeals = (mealList) => async (dispatch) => {
    dispatch({ type: MEAL_LOADING });
    const mealsToSave = mealList.map((meal) => ({
        ...meal,
        createdAt: new Date(),
        updatedAt: new Date(),
        docId: uuid()
    }));

    try {
        for (let i = 0; i < mealsToSave.length; i += 1) {
            const meal = mealsToSave[i];
            await meals.doc(meal.docId).set(meal);
        }
        dispatch(addBatchInFoodAggregate(mealsToSave));
        dispatch({ type: MEALS_CREATE_SUCCESS, payload: { meals: mealsToSave, isNew: true } });
        dispatch({
            type: EVENT_MEAL,
            payload: { type: 'meal', code: 'batch-created', value: mealsToSave }
        });
    } catch (error) {
        console.log(error);
        dispatch({ type: MEALS_CREATE_FAIL, payload: { error } });
    }
    dispatch({ type: MEAL_END_LOADING });
};

export const clear = () => (dispatch) => {
    dispatch({ type: CLEAR_MEALS });
};

export const fetchNext = (amount, lastFetchedId) => async (dispatch) => {
    dispatch({ type: MEAL_LOADING });
    try {
        let mealList;
        if (lastFetchedId) {
            const lastDoc = await meals.doc(lastFetchedId).get();
            mealList = await meals
                .orderBy('date', 'desc')
                .startAfter(lastDoc)
                .limit(amount)
                .get()
                .then((items) => items.docs.map((item) => item.data()));
        } else {
            mealList = await meals
                .orderBy('date', 'desc')
                .limit(amount)
                .get()
                .then((items) => items.docs.map((item) => item.data()));
        }
        dispatch({ type: MEAL_FETCH_SUCCESS, payload: { meals: mealList } });
    } catch (error) {
        dispatch({ type: MEAL_FETCH_FAIL, payload: { error } });
    }
    dispatch({ type: MEAL_END_LOADING });
};

/**
 * Remove a meal from firebase
 * @param {Meal} meal
 * @return {function(*): Promise<void>}
 */
export const removeMeal = (meal) => async (dispatch) => {
    dispatch({ type: MEAL_LOADING });
    try {
        await meals.doc(meal.docId).delete();
        dispatch(removeFromFoodAggregate(meal));
        dispatch({ type: MEAL_DELETE_SUCCESS, payload: { docId: meal.docId } });
    } catch (error) {
        dispatch({ type: MEAL_DELETE_FAIL, payload: { error } });
    }
    dispatch({ type: MEAL_END_LOADING });
};
