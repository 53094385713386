import React, { useState } from 'react';
import { createDayMeals } from '../../actions/meal';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import SaveIcon from '@material-ui/icons/Save';
import { Box } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DayMealForm from '../Forms/DayMealForm';
import { LUNCH_TIMES } from '../../const';
import { isMealComplete } from '../../utils/meal';
import Icon from '../Icon/Icon';

function CreateDayMeals(props) {
    const [selectedDate, handleDateChange] = useState(new Date());
    const [lunchMeal, setLunchMeal] = useState(null);
    const [teatimeMeal, setTeatimeMeal] = useState(null);

    function handleClose() {
        props.onClose();
    }

    function save() {
        const meals = [];
        if (isMealComplete(lunchMeal)) {
            meals.push({
                ...lunchMeal,
                mealMoment: LUNCH_TIMES.lunch,
                date: selectedDate.toISOString()
            });
        }
        if (isMealComplete(teatimeMeal)) {
            meals.push({
                ...teatimeMeal,
                mealMoment: LUNCH_TIMES.teatime,
                date: selectedDate.toISOString()
            });
        }
        props.createDayMeals(meals);
        props.onClose();
    }

    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="add-meal-form-dialog-title"
                fullScreen
            >
                <AppBar style={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" style={{ flex: 1 }}>
                            Ajouter les repas d&apos;une journée
                        </Typography>
                        <Button color="primary" onClick={save}>
                            <SaveIcon style={{ marginRight: '0.1em' }} /> Enregistrer
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box style={{ overflow: 'auto', padding: '1em' }}>
                    <div className="col full">
                        <div className="row wrap space-evenly">
                            <div className="row mt-m space-evenly wrap">
                                <DatePicker
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    inputVariant="outlined"
                                    label="Date des repas"
                                    style={{ minWidth: '200px', maxWidth: '450px', width: '100%' }}
                                />
                            </div>
                        </div>
                        <div className="row mt-m space-evenly wrap">
                            <div className="col full" style={{ maxWidth: '550px' }}>
                                <Paper
                                    style={{
                                        padding: '1em',
                                        marginRight: '0.25em',
                                        marginLeft: '0.25em'
                                    }}
                                >
                                    <div className="row justify-center">
                                        <Icon type={LUNCH_TIMES.lunch} />
                                        <Typography variant="h6" style={{ marginLeft: '0.5em' }}>
                                            Déjeuner
                                        </Typography>
                                    </div>
                                    <div className="row justify-center mt-s">
                                        <DayMealForm key={1} onChange={setLunchMeal} />
                                    </div>
                                </Paper>
                            </div>
                            <div className="col full" style={{ maxWidth: '550px' }}>
                                <Paper
                                    style={{
                                        padding: '1em',
                                        marginRight: '0.25em',
                                        marginLeft: '0.25em'
                                    }}
                                >
                                    <div className="row justify-center">
                                        <Icon type={LUNCH_TIMES.teatime} />
                                        <Typography variant="h6" style={{ marginLeft: '0.5em' }}>
                                            Goûter
                                        </Typography>
                                    </div>
                                    <div className="row justify-center mt-s">
                                        <DayMealForm key={2} onChange={setTeatimeMeal} />
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </Box>
            </Dialog>
        </React.Fragment>
    );
}

CreateDayMeals.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    createDayMeals: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
    createDayMeals: (meal) => dispatch(createDayMeals(meal))
});

export default connect(null, mapDispatchToProps)(CreateDayMeals);
