import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';

export default function DisplayDate(props) {
    const momentDate = moment(props.date);
    return <React.Fragment>{momentDate.format('DD/MM/YYYY')}</React.Fragment>;
}

DisplayDate.propTypes = {
    date: PropTypes.string.isRequired
};
