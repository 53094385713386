import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAuthIsLoggedIn } from '../../selectors/auth';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CreateMealModal from '../CreateMealModal/CreateMealModal';
import './AddMeal.css';
import CreateDayMeals from '../CreateDayMeals/CreateDayMeals';

function AddMeal(props) {
    const [openAddMeal, setOpenAddMeal] = React.useState(false);
    const [openAddDayMeals, setOpenAddDayMeals] = React.useState(false);

    const handleAddMeal = () => {
        setOpenAddMeal(true);
    };

    const handleAddDayMeals = () => {
        setOpenAddDayMeals(true);
    };

    function onClose() {
        setOpenAddMeal(false);
        setOpenAddDayMeals(false);
    }

    if (!props.isLoggedIn) return null;
    return (
        <React.Fragment>
            <Fab
                color="primary"
                variant="extended"
                className="add-meal__create-meal-button"
                aria-label="add-meal"
                size="small"
                onClick={handleAddMeal}
            >
                <AddIcon />
                Repas
            </Fab>
            <Fab
                color="primary"
                variant="extended"
                className="add-meal__create-day-meals-button"
                aria-label="add-day-meals"
                size="small"
                onClick={handleAddDayMeals}
            >
                <AddIcon />
                journée
            </Fab>
            <CreateMealModal open={openAddMeal} onClose={onClose} />
            <CreateDayMeals open={openAddDayMeals} onClose={onClose} />
        </React.Fragment>
    );
}

AddMeal.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = (store) => ({
    isLoggedIn: getAuthIsLoggedIn(store)
});

export default connect(mapStateToProps)(AddMeal);
