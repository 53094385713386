import moment from 'moment';

/**
 *
 * @param {string} a
 * @param {string} b
 * @return {number}
 */
export default function compareDate(a = new Date().toISOString(), b = new Date().toISOString()) {
    const ma = moment(a);
    const mb = moment(b);

    if (ma.year() > mb.year() || ma.month() > mb.month() || ma.date() > mb.date()) {
        return 1;
    }
    if (ma.year() < mb.year() || ma.month() < mb.month() || ma.date() < mb.date()) {
        return -1;
    }
    return 0;
}
