import {
    FOOD_AGGREGATE_END_LOADING,
    FOOD_AGGREGATE_FETCH_ALL_FAIL,
    FOOD_AGGREGATE_FETCH_ALL_SUCCESS,
    FOOD_AGGREGATE_LOADING
} from '../types/foodAggregate';

const initialState = {
    isLoading: false,
    error: null,
    hasError: false,
    foodAggregates: [],
    changes: 0,
    lastEventCode: null,
    lastEventValue: null
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FOOD_AGGREGATE_LOADING: {
            return {
                ...state,
                isLoading: true,
                hasError: false,
                error: null
            };
        }
        case FOOD_AGGREGATE_END_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case FOOD_AGGREGATE_FETCH_ALL_SUCCESS: {
            return {
                ...state,
                foodAggregates: payload.foods,
                error: null,
                hasError: false
            };
        }
        case FOOD_AGGREGATE_FETCH_ALL_FAIL: {
            return {
                ...state,
                foodAggregates: [],
                error: payload.error,
                hasError: true
            };
        }
        default: {
            return state;
        }
    }
};
