import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAllMeals, getMealIsLoading } from '../../selectors/meal';
import { clear, fetchNext } from '../../actions/meal';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import MealHistoryLine from '../../components/MealHistoryLine/MealHistoryLine';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { getAuthIsLoggedIn } from '../../selectors/auth';
import CheckMeals from '../../components/CheckMeals/CheckMeals';

function MealHistory(props) {
    const [range, setRange] = useState({ amount: 10, lastFetchId: '' });
    const { fetchNext, meals, isLoading, clear, isLoggedIn } = props;

    useEffect(() => {
        if (range.lastFetchId === '') {
            clear();
        }
        fetchNext(range.amount, range.lastFetchId);
    }, [fetchNext, range.amount, range.lastFetchId, clear]);

    function handleFetchNext() {
        const { amount } = range;
        const newRange = { amount, lastFetchId: meals[meals.length - 1].docId };
        setRange(newRange);
    }

    return (
        <React.Fragment>
            <Typography variant={'h3'}>Historique des repas</Typography>
            <div className="row wrap space-evenly mt-l">
                <CheckMeals latestMeal={meals[0]} />
            </div>
            <div className="row wrap space-evenly mt-l">
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Ingrédients</TableCell>
                                <TableCell align="right">Quantité</TableCell>
                                <TableCell align="right">Avis</TableCell>
                                {isLoggedIn ? <TableCell align="right" /> : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {meals.map((meal) => (
                                <MealHistoryLine key={meal.docId} meal={meal} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className="row wrap space-evenly">
                {isLoading ? <CircularProgress className="mt-l" /> : null}
            </div>
            <div className="row wrap space-evenly mt-l">
                <Button variant="contained" color="primary" onClick={handleFetchNext}>
                    Afficher plus
                </Button>
            </div>
        </React.Fragment>
    );
}

MealHistory.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    meals: PropTypes.array.isRequired,
    fetchNext: PropTypes.func.isRequired,
    clear: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = (store) => ({
    isLoading: getMealIsLoading(store),
    meals: getAllMeals(store),
    isLoggedIn: getAuthIsLoggedIn(store)
});

const mapDispatchToProps = (dispatch) => ({
    fetchNext: (amount, lastFetchedId) => dispatch(fetchNext(amount, lastFetchedId)),
    clear: () => dispatch(clear())
});

export default connect(mapStateToProps, mapDispatchToProps)(MealHistory);
