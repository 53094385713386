import SignIn from '../screens/SignIn/SignIn';
import NotFound from '../components/NotFound/NotFound';
import Home from '../screens/Home/Home';
import Tried from '../screens/Tried/Tried';
import MealHistory from '../screens/MealHistory/MealHistory';
import FoodList from '../screens/FoodList/FoodList';
import FoodCalendar from '../screens/FoodCalendar/FoodCalendar';

export default [
    {
        path: '/',
        exact: true,
        component: Home,
        name: "Quoi que c'est qu'il mange",
        meta: {
            visible: true
        }
    },
    {
        path: '/tried',
        exact: true,
        component: Tried,
        name: 'Aliments essayés',
        meta: {
            visible: true
        }
    },
    {
        path: '/history',
        exact: true,
        component: MealHistory,
        name: 'Historique',
        meta: {
            visible: true
        }
    },
    {
        path: '/food-list',
        exact: true,
        component: FoodList,
        name: 'Liste des ingrédients',
        meta: {
            visible: true
        }
    },
    {
        path: '/food-calendar',
        exact: true,
        component: FoodCalendar,
        name: 'Calendrier',
        meta: {
            visible: true
        }
    },
    {
        path: '/sign-in',
        component: SignIn,
        name: 'Connexion',
        meta: {
            visible: false,
            noAuthOnly: true
        }
    },
    {
        path: '*',
        component: NotFound,
        meta: {
            visible: false
        }
    }
];
