import React from 'react';
import PropTypes from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import Icon from '../Icon/Icon';
import CardContent from '@material-ui/core/CardContent';
import PleasureRating from '../FormElements/PleasureRating/PleasureRating';
import DisplayDate from '../DisplayDate/DisplayDate';
import Card from '@material-ui/core/Card';

export default function FoodAggregationCard(props) {
    const {
        aggregate: { key, text, timesTried, satisfactionAvg, firstTry, lastTry, quantities }
    } = props;

    return (
        <React.Fragment>
            <Card style={{ margin: '0.35em' }}>
                <CardHeader
                    avatar={<Icon type={key} size={'lg'} />}
                    title={text}
                    titleTypographyProps={{ variant: 'h6' }}
                />
                <CardContent>
                    <div className="col full">
                        <div className="row">Essayé : {timesTried} fois</div>
                        <div className="row">
                            <PleasureRating
                                readOnly={true}
                                value={satisfactionAvg}
                                labelled={false}
                            />
                        </div>
                        <div className="row">
                            Premier essai : <DisplayDate date={firstTry.date} />
                        </div>
                        <div className="row mt-xs">
                            Dernier essai : <DisplayDate date={lastTry.date} />
                        </div>
                        <div className="row mt-xs">
                            Quantité moyenne :&nbsp;
                            {(
                                quantities.reduce(
                                    (acc, { quantity }) => acc + parseInt(quantity, 10),
                                    0
                                ) / quantities.length
                            ).toFixed(0)}
                            &nbsp;g
                        </div>
                    </div>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}

FoodAggregationCard.propTypes = {
    aggregate: PropTypes.object.isRequired
};
