import React from 'react';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import './PleasureRating.css';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon style={{ fontSize: '1.875rem' }} />,
        label: 'Very Dissatisfied',
        customClass: 'very-dissatisfied'
    },
    2: {
        icon: <SentimentDissatisfiedIcon style={{ fontSize: '1.875rem' }} />,
        label: 'Dissatisfied',
        customClass: 'dissatisfied'
    },
    3: {
        icon: <SentimentSatisfiedIcon style={{ fontSize: '1.875rem' }} />,
        label: 'Neutral',
        customClass: 'neutral'
    },
    4: {
        icon: <SentimentSatisfiedAltIcon style={{ fontSize: '1.875rem' }} />,
        label: 'Satisfied',
        customClass: 'satisfied'
    },
    5: {
        icon: <SentimentVerySatisfiedIcon style={{ fontSize: '1.875rem' }} />,
        label: 'Very Satisfied',
        customClass: 'very-satisfied'
    }
};

function IconContainer(props) {
    const { value, ...other } = props;
    const { customClass, icon } = customIcons[value];
    const computedClassName = `${other.className}${customClass ? ' ' + customClass : ''}`;
    return (
        <span {...other} className={computedClassName}>
            {icon}
        </span>
    );
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired
};

export default function PleasureRating({
    readOnly = false,
    value = 3,
    onChange = () => {},
    labelled = true
}) {
    function handleChange(e, val) {
        onChange(val);
    }

    return (
        <React.Fragment>
            <Box borderColor="transparent">
                {labelled ? (
                    <Typography component="legend" className="MuiFormLabel-root">
                        Avis
                    </Typography>
                ) : null}
                <Rating
                    className="PleasureRating"
                    name={`pleasure-rating-${Math.random()}`}
                    readOnly={readOnly}
                    value={value || 1}
                    onChange={handleChange}
                    getLabelText={(val) => customIcons[val || 1].label}
                    IconContainerComponent={IconContainer}
                />
            </Box>
        </React.Fragment>
    );
}

PleasureRating.propTypes = {
    readOnly: PropTypes.bool,
    value: PropTypes.number,
    onChange: PropTypes.func,
    labelled: PropTypes.bool
};
