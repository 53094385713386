import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FRUITS_BY_MONTHS, MONTHS_MAP, VEGETABLES_BY_MONTHS } from '../../const';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { getFoodAggregateIsLoading, getMapOfTriedFood } from '../../selectors/foodAggregate';
import { getAll } from '../../actions/foodAggregate';
import ListOfIngredients from '../../components/ListOfIngredients/ListOfIngredients';
import SearchIngredient from '../../components/FormElements/SearchIngredient';
import HideTriedIngredientsSwitch from '../../components/FormElements/HideTriedIngredientsSwitch';

function computeMonthOrder() {
    const actualMonth = new Date().getMonth() + 1;
    const months = [];
    for (let i = actualMonth; i <= 12; i += 1) {
        months.push(MONTHS_MAP[i]);
    }
    for (let i = 1; i < actualMonth; i += 1) {
        months.push(MONTHS_MAP[i]);
    }
    return months;
}

function FoodCalendar(props) {
    const orderedMonths = computeMonthOrder();
    const { fetchAll, triedFood } = props;
    const [hideTried, setHideTried] = useState(false);
    const [filterFood, setFilterFood] = useState('');

    useEffect(() => {
        fetchAll();
    }, [fetchAll]);

    return (
        <React.Fragment>
            <div className="row mt-s space-evenly wrap">
                <div className="col justify-center">
                    <HideTriedIngredientsSwitch checked={hideTried} onChange={setHideTried} />
                </div>
                <div className="col justify-center">
                    <SearchIngredient value={filterFood} onChange={setFilterFood} />
                </div>
            </div>
            {orderedMonths.map((month, i) => (
                <div className="row wrap mt-s" key={i}>
                    <Paper key={i} className="p-s full">
                        <div className="col full">
                            <div className="row">
                                <Typography variant="h5">{month}</Typography>
                            </div>
                            <div className="pl-m">
                                <div className="row">
                                    <Typography variant="h6">Fruits</Typography>
                                </div>
                                <div className="row wrap space-evenly">
                                    <ListOfIngredients
                                        ingredients={FRUITS_BY_MONTHS[i + 1].map((ingredient) => ({
                                            ...ingredient,
                                            tried: triedFood[ingredient.key]
                                        }))}
                                        showTried={!hideTried}
                                        filter={filterFood}
                                    />
                                </div>
                                <div className="row">
                                    <Typography variant="h6">Légumes</Typography>
                                </div>
                                <div className="row wrap space-evenly">
                                    <ListOfIngredients
                                        ingredients={VEGETABLES_BY_MONTHS[i + 1].map(
                                            (ingredient) => ({
                                                ...ingredient,
                                                tried: triedFood[ingredient.key]
                                            })
                                        )}
                                        showTried={!hideTried}
                                        filter={filterFood}
                                    />
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div>
            ))}
        </React.Fragment>
    );
}

FoodCalendar.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    triedFood: PropTypes.object.isRequired,
    fetchAll: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isLoading: getFoodAggregateIsLoading(state),
    triedFood: getMapOfTriedFood(state)
});

const mapDispatchToProps = (dispatch) => ({
    fetchAll: () => dispatch(getAll())
});

export default connect(mapStateToProps, mapDispatchToProps)(FoodCalendar);
