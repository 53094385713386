import React from 'react';
import PropTypes from 'prop-types';
import IngredientCard from '../IngredientCard/IngredientCard';
import { Typography } from '@material-ui/core';

export default function ListOfIngredients(props) {
    const { ingredients, filter, showTried } = props;
    const displayableIngredients = ingredients.filter(
        ({ text, tried }) =>
            !(!showTried && tried) && text.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
    if (displayableIngredients.length === 0) {
        return <Typography>Aucune correspondance</Typography>;
    }
    return (
        <React.Fragment>
            {displayableIngredients.map((ingredient, i) => (
                <IngredientCard ingredient={ingredient} key={i} tried={ingredient.tried} />
            ))}
        </React.Fragment>
    );
}

ListOfIngredients.propTypes = {
    ingredients: PropTypes.array.isRequired,
    filter: PropTypes.string.isRequired,
    showTried: PropTypes.bool.isRequired
};
