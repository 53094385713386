import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FoodSelector from '../FormElements/FoodSelector';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PleasureRating from '../FormElements/PleasureRating/PleasureRating';

export default function DayMealForm(props) {
    const { onChange } = props;
    const [foodList, setFoodList] = useState([]);
    const [satisfaction, setSatisfaction] = useState(3);
    const [quantityUnit, setQuantityUnit] = useState('g');
    const [quantity, setQuantity] = useState('130');
    const [informationNote, setInformationNote] = useState('');

    useEffect(() => {
        onChange({
            food: foodList.map((food) => food.key),
            satisfaction,
            quantityUnit,
            quantity,
            informationNote
        });
    }, [onChange, foodList, satisfaction, quantityUnit, quantity, informationNote]);

    return (
        <React.Fragment>
            <div className="row" style={{ maxWidth: '500px' }}>
                <div className="col full">
                    <div className="row">
                        <FoodSelector onChange={(e) => setFoodList(e)} />
                    </div>
                    <div className="row mt-m">
                        <TextField
                            label="Quantité"
                            type="text"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            style={{ width: '100%' }}
                        />
                        <FormControl style={{ width: '10%' }}>
                            <InputLabel>Unité</InputLabel>
                            <Select
                                value={quantityUnit}
                                onChange={(e) => setQuantityUnit(e.target.value)}
                            >
                                <MenuItem value={'g'}>g</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="row space-evenly wrap">
                        <div
                            className="col full mt-m"
                            style={{ minWidth: '220px', maxWidth: '350px' }}
                        >
                            <FormControl>
                                <TextField
                                    label="Informations supplémentaires"
                                    type="text"
                                    value={informationNote}
                                    onChange={(e) => setInformationNote(e.target.value)}
                                />
                            </FormControl>
                        </div>
                        <div className="col mt-m">
                            <PleasureRating
                                value={satisfaction}
                                onChange={(e) => setSatisfaction(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

DayMealForm.propTypes = {
    onChange: PropTypes.func.isRequired
};
