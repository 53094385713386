import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

export default function SearchIngredient(props) {
    const { value, onChange } = props;
    return (
        <React.Fragment>
            <TextField
                size="small"
                id="outlined-basic"
                label="Rechercher"
                variant="outlined"
                value={value}
                style={{ minWidth: '300px' }}
                onChange={(event) => onChange(event.target.value)}
            />
        </React.Fragment>
    );
}
SearchIngredient.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};
