import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import event from './event';
import meal from './meal';
import foodAggregate from './foodAggregate';

export default combineReducers({
    app,
    auth,
    event,
    meal,
    foodAggregate
});
