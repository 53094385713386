/**
 * @typedef FoodAggregate
 * @property {string} food Ingredient Aggregated
 * @property {number} timesTried
 * @property {Object} lastTry
 * @property {string} lastTry.moment
 * @property {string} lastTry.date
 * @property {Object} firstTry
 * @property {string} firstTry.moment
 * @property {string} firstTry.date
 * @property {number} satisfactionSum
 * @property {number} satisfactionAvg
 * @property {Object[]} quantities
 * @property {string} quantities.docId
 * @property {number} quantities.quantity
 * @property {Date} [createdAt]
 * @property {Date} [updatedAt]
 */
import { foodAggregates, db } from '../firebase';
import {
    FOOD_AGGREGATE_END_LOADING,
    FOOD_AGGREGATE_FETCH_ALL_FAIL,
    FOOD_AGGREGATE_FETCH_ALL_SUCCESS,
    FOOD_AGGREGATE_LOADING
} from '../types/foodAggregate';

/**
 * Add data into an aggregate
 * @param {Meal} meal
 * @returns {*}
 */
export const addInFoodAggregate = (meal) => (dispatch) => {
    dispatch({ type: FOOD_AGGREGATE_LOADING });
    const promises = meal.food.map((food) => addInAggregate(food, meal));
    Promise.all(promises)
        .then(() => dispatch({ type: FOOD_AGGREGATE_END_LOADING }))
        .catch(() => dispatch({ type: FOOD_AGGREGATE_END_LOADING }));
};

/**
 *
 * @param {Meal[]} meals
 * @return {function(*): Promise<void>}
 */
export const addBatchInFoodAggregate = (meals) => async (dispatch) => {
    dispatch({ type: FOOD_AGGREGATE_LOADING });
    try {
        for (let i = 0; i < meals.length; i += 1) {
            const meal = meals[i];
            await Promise.all(meal.food.map((food) => addInAggregate(food, meal)));
        }
    } catch (error) {
        console.log('aggregate save fail', error);
    }
    dispatch({ type: FOOD_AGGREGATE_END_LOADING });
};

/**
 *
 * @param {string} ingredient
 * @param {Meal} meal
 * @return {Promise<any>}
 */
async function addInAggregate(ingredient, meal) {
    const aggregateRef = foodAggregates.doc(ingredient);

    return db.runTransaction((transaction) => {
        return transaction.get(aggregateRef).then((res) => {
            if (!res.exists) {
                return transaction.set(aggregateRef, createDefaultAggregation(ingredient, meal));
            }

            const { timesTried, satisfactionSum, quantities } = res.data();
            const newTimesTried = timesTried + 1;
            const newSatisfactionSum = satisfactionSum + meal.satisfaction;
            const newSatisfactionAvg = newSatisfactionSum / newTimesTried;
            const newQuantities = quantities.concat({
                docId: meal.docId,
                quantity: meal.quantity / meal.food.length
            });

            return transaction.update(aggregateRef, {
                timesTried: newTimesTried,
                satisfactionSum: newSatisfactionSum,
                satisfactionAvg: newSatisfactionAvg,
                lastTry: {
                    moment: meal.mealMoment,
                    date: meal.date
                },
                quantities: newQuantities
            });
        });
    });
}

export const getAll = () => async (dispatch) => {
    dispatch({ type: FOOD_AGGREGATE_LOADING });
    try {
        const foods = await foodAggregates
            .get()
            .then((items) => items.docs.map((item) => item.data()));
        dispatch({ type: FOOD_AGGREGATE_FETCH_ALL_SUCCESS, payload: { foods } });
    } catch (error) {
        dispatch({ type: FOOD_AGGREGATE_FETCH_ALL_FAIL, payload: { error } });
    }
    dispatch({ type: FOOD_AGGREGATE_END_LOADING });
};

/**
 *
 * @param {Meal} meal
 * @return {function(*): void}
 */
export const removeFromFoodAggregate = (meal) => (dispatch) => {
    const promises = meal.food.map((food) => removeFromAggregate(food, meal));
    Promise.all(promises)
        .then(() => dispatch({ type: FOOD_AGGREGATE_END_LOADING }))
        .catch(() => dispatch({ type: FOOD_AGGREGATE_END_LOADING }));
};

/**
 *
 * @param {string} ingredient
 * @param {Meal} meal
 * @return {Promise<any>}
 */
async function removeFromAggregate(ingredient, meal) {
    const aggregateRef = foodAggregates.doc(ingredient);

    return db.runTransaction((transaction) => {
        return transaction.get(aggregateRef).then((res) => {
            const { timesTried, satisfactionSum, quantities } = res.data();
            const newTimesTried = timesTried - 1;
            if (newTimesTried === 0) {
                return transaction.delete(aggregateRef);
            }
            const newSatisfactionSum = satisfactionSum - meal.satisfaction;
            const newSatisfactionAvg = newSatisfactionSum / newTimesTried;
            const newQuantities = quantities.filter((quantity) => quantity.docId !== meal.docId);

            return transaction.update(aggregateRef, {
                timesTried: newTimesTried,
                satisfactionSum: newSatisfactionSum,
                satisfactionAvg: newSatisfactionAvg,
                lastTry: {
                    moment: meal.mealMoment,
                    date: meal.date
                },
                quantities: newQuantities
            });
        });
    });
}

/**
 *
 * @param {string} food
 * @param {Meal} meal
 * @return {FoodAggregate}
 */
function createDefaultAggregation(food, meal) {
    const tryDate = {
        moment: meal.mealMoment,
        date: meal.date
    };
    const now = new Date();
    return {
        food,
        firstTry: tryDate,
        lastTry: tryDate,
        timesTried: 1,
        satisfactionSum: meal.satisfaction,
        satisfactionAvg: meal.satisfaction,
        createdAt: now,
        updatedAt: now,
        quantities: [{ docId: meal.docId, quantity: meal.quantity }]
    };
}
