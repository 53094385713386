import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import './InformationModalLine.css';

export default class InformationModalLine extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string.isRequired,
        text: PropTypes.node.isRequired,
        className: PropTypes.string
    };

    render() {
        const { className, title, text } = this.props;
        const classes = `row ${className || ''}`;
        return (
            <React.Fragment>
                <div className={classes}>
                    <div className="col">
                        <Typography>
                            <b>{title}&nbsp;</b>
                        </Typography>
                    </div>
                    <div className="col information-modal-line__col">
                        <Typography>{text}</Typography>
                    </div>
                </div>
                <div className="row information-modal-line__row">
                    <div className="col full">
                        <Typography>{text}</Typography>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
