import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function Icon(props) {
    const [size] = useState(props.size || 'sm');

    return (
        <React.Fragment>
            {props.label ? (
                <div className={`icon-${size} ${props.type}`} title={props.label} />
            ) : (
                <div className={`icon-${size} ${props.type}`} />
            )}
        </React.Fragment>
    );
}

Icon.propTypes = {
    type: PropTypes.string.isRequired,
    size: PropTypes.string,
    label: PropTypes.string
};
