import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getAppVersion } from '../../selectors/app';
import InformationModalLine from '../InformationModalLine/InformationModalLine';

class InformationModal extends React.PureComponent {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        version: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired
    };

    render() {
        const { open, onClose, version } = this.props;
        return (
            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={() => onClose()}
                    aria-label="modal informative"
                    maxWidth="md"
                >
                    <DialogTitle className="closable-modal__title">
                        <div className="row space-between">
                            <div className="col justify-center">À propos</div>
                            <div className="col justify-center">
                                <IconButton aria-label="close" onClick={() => onClose()}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <InformationModalLine
                            title={'Développement du site :'}
                            text={<span>Morgan&nbsp;DENIS</span>}
                        />
                        <InformationModalLine
                            title={'Tests et conseils esthétiques :'}
                            text={<span>Audrey&nbsp;BONHOMME</span>}
                            className="mt-s"
                        />
                        <InformationModalLine
                            title={'Participation indispensable :'}
                            text={<span>Lucas</span>}
                            className="mt-s"
                        />
                        <InformationModalLine
                            title={'Version :'}
                            text={<span>{version}</span>}
                            className="mt-s"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onClose()}>Fermer</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    version: getAppVersion(store)
});

export default connect(mapStateToProps)(withRouter(InformationModal));
