import { WINDOW_RESIZE } from '../const';

export const windowResize = (height, width) => ({
    type: WINDOW_RESIZE,
    payload: {
        height,
        width
    }
});

export default windowResize;
