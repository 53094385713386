import React from 'react';
import './MainContainer.style.css';
import { Switch, withRouter, Route } from 'react-router-dom';
import routes from '../../services/routes';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

class MainContainer extends React.PureComponent {
    render() {
        return (
            <div className="scrollable-container">
                <div className="container">
                    <Switch>
                        {routes.map((route, i) =>
                            route.meta.authOnly ? (
                                <PrivateRoute key={i} {...route} />
                            ) : (
                                <Route key={i} {...route} />
                            )
                        )}
                    </Switch>
                </div>
            </div>
        );
    }
}

export default withRouter(MainContainer);
