import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { MAPPED_INGREDIENTS } from '../../const';
import { getAllFoodAggregates, getFoodAggregateIsLoading } from '../../selectors/foodAggregate';
import { getAll } from '../../actions/foodAggregate';
import CircularProgress from '@material-ui/core/CircularProgress';
import FoodAggregationCard from '../../components/FoodAggregationCard/FoodAggregationCard';

function Tried(props) {
    const [computedFoods, setComputedFoods] = useState([]);
    const [initialised, setInitialised] = useState(false);

    const { fetchAll, foodAggregates } = props;
    useEffect(() => {
        fetchAll();
    }, [fetchAll]);

    useEffect(() => {
        if (!initialised || computedFoods.length !== foodAggregates.length) {
            setComputedFoods(
                foodAggregates
                    .map((aggregate) => ({
                        ...aggregate,
                        ...MAPPED_INGREDIENTS[aggregate.food]
                    }))
                    .sort((a, b) => (a.text >= b.text ? 1 : -1))
            );
            setInitialised(true);
        }
    }, [foodAggregates, computedFoods.length, initialised]);

    return (
        <React.Fragment>
            <Typography variant={'h3'}>Aliments Essayés</Typography>
            <div className="row wrap space-evenly mt-l">
                {props.isLoading ? (
                    <CircularProgress className="mt-l" />
                ) : (
                    computedFoods.map((aggregate) => (
                        <FoodAggregationCard key={aggregate.food} aggregate={aggregate} />
                    ))
                )}
            </div>
        </React.Fragment>
    );
}

Tried.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    foodAggregates: PropTypes.array.isRequired,
    fetchAll: PropTypes.func.isRequired
};

const mapStateToProps = (store) => ({
    isLoading: getFoodAggregateIsLoading(store),
    foodAggregates: getAllFoodAggregates(store)
});

const mapDispatchToProps = (dispatch) => ({
    fetchAll: () => dispatch(getAll())
});

export default connect(mapStateToProps, mapDispatchToProps)(Tried);
