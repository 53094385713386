import React, { useState } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { DatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import MealMomentSelector from '../FormElements/MealMomentSelector';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { Box } from '@material-ui/core';
import { createMeal } from '../../actions/meal';
import DayMealForm from '../Forms/DayMealForm';

function CreateMealModal(props) {
    const [meal, setMeal] = useState(null);
    const [mealMoment, setMealMoment] = useState('lunch');
    const [selectedDate, handleDateChange] = useState(new Date());

    function handleClose() {
        props.onClose();
    }

    function save() {
        props.createMeal({
            ...meal,
            mealMoment,
            date: selectedDate.toISOString()
        });
        reset();
        props.onClose();
    }

    function reset() {
        handleDateChange(new Date());
        setMealMoment('lunch');
    }

    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="add-meal-form-dialog-title"
                fullScreen
            >
                <AppBar style={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" style={{ flex: 1 }}>
                            Ajouter un repas
                        </Typography>
                        <Button color="primary" onClick={save}>
                            <SaveIcon style={{ marginRight: '0.1em' }} /> Enregistrer
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box style={{ overflow: 'auto', padding: '1em' }}>
                    <div className="col full">
                        <div className="row mt-m space-evenly wrap">
                            <DatePicker
                                value={selectedDate}
                                onChange={handleDateChange}
                                inputVariant="outlined"
                                label="Date du repas"
                                style={{ minWidth: '200px', maxWidth: '450px', width: '100%' }}
                            />
                        </div>
                        <div className="row mt-m space-evenly wrap">
                            <div className="col full" style={{ maxWidth: '500px' }}>
                                <MealMomentSelector
                                    value={mealMoment}
                                    onChange={(e) => setMealMoment(e)}
                                />
                            </div>
                        </div>
                        <div className="row mt-m space-evenly wrap">
                            <DayMealForm key={1} onChange={setMeal} />
                        </div>
                    </div>
                </Box>
            </Dialog>
        </React.Fragment>
    );
}

CreateMealModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    createMeal: PropTypes.func.isRequired
};
const mapDispatchToProps = (dispatch) => ({
    createMeal: (meal) => dispatch(createMeal(meal))
});

export default connect(null, mapDispatchToProps)(CreateMealModal);
