import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import Paper from '@material-ui/core/Paper';

export default function IngredientCard(props) {
    const { ingredient, tried } = props;
    const { key, text } = ingredient;
    return (
        <React.Fragment>
            <Paper className="m-xs p-s" style={{ minWidth: '85px' }} elevation={tried ? 3 : 1}>
                <div className="col">
                    <div className="row justify-center">
                        <div className="col justify-center">
                            <span>{text}</span>
                        </div>
                        {tried ? (
                            <div className="col justify-center ml-xs">
                                <Icon type="checked" size="xs" />
                            </div>
                        ) : null}
                    </div>
                    <div className="row justify-center mt-xs">
                        <Icon type={key} size="lg" />
                    </div>
                </div>
            </Paper>
        </React.Fragment>
    );
}

IngredientCard.propTypes = {
    ingredient: PropTypes.object.isRequired,
    tried: PropTypes.bool
};
