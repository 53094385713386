export default [
    { key: 'apple', text: 'Pomme', months: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12] },
    { key: 'apricot', text: 'Abricot', months: [6, 7, 8] },
    { key: 'banana', text: 'Banane', months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
    { key: 'brugnon', text: 'Brugnon', months: [7, 8] },
    { key: 'nectarine', text: 'Nectarine', months: [7, 8] },
    { key: 'blackberry', text: 'Mûre', months: [8, 9] },
    { key: 'blueberry', text: 'Myrtille', months: [8, 9] },
    { key: 'cherry', text: 'Cerise', months: [5, 6, 7] },
    { key: 'citrus', text: 'Citron', months: [1, 2, 3, 4] },
    { key: 'clementine', text: 'Clémentine', months: [1, 2, 11, 12] },
    { key: 'coconut', text: 'Noix de coco', months: [1, 2, 11, 12] },
    { key: 'fig', text: 'Figue', months: [7, 8] },
    { key: 'gooseberry', text: 'Groseille', months: [6, 7] },
    { key: 'grapefruit', text: 'Pamplemousse', months: [1, 2, 3, 4, 9, 10, 11, 12] },
    { key: 'guava', text: 'Goyave', months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
    { key: 'grapes', text: 'Raisin', months: [9, 10, 11] },
    { key: 'kiwi', text: 'Kiwi', months: [1, 2, 3, 11, 12] },
    { key: 'lime', text: 'Citron vert', months: [1, 2, 3, 4] },
    { key: 'mango', text: 'Mangue', months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] },
    { key: 'melon', text: 'Melon', months: [6, 7, 8, 9] },
    { key: 'mirabelle-plum', text: 'Mirabelle', months: [7] },
    { key: 'orange', text: 'Orange', months: [1, 2, 3, 11, 12] },
    {
        key: 'passion-fruit',
        text: 'Fruit de la passion',
        months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    },
    { key: 'peach', text: 'Pêche', months: [7, 8, 9] },
    { key: 'pear', text: 'Poire', months: [1, 2, 3, 8, 9, 10, 11, 12] },
    { key: 'persimmon', text: 'Kaki', months: [1, 10, 11, 12] },
    { key: 'pineapple', text: 'Ananas', months: [1, 2, 3, 11, 12] },
    { key: 'plum', text: 'Prune', months: [7, 8, 9] },
    { key: 'prune', text: 'Pruneau', months: [8, 9] },
    { key: 'quince', text: 'Coing', months: [10, 11] },
    { key: 'raspberry', text: 'Framboise', months: [5, 6, 7] },
    { key: 'rhubarb', text: 'Rhubarbe', months: [4, 5] },
    { key: 'strawberry', text: 'Fraise', months: [5, 6, 7] },
    { key: 'tangerine', text: 'Mandarine', months: [11, 12] },
    { key: 'watermelon', text: 'Pasteque', months: [8, 9] }
];
