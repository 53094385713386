export default [
    { key: 'butter', text: 'Beurre' },
    { key: 'butter-biscuit', text: 'Petit beurre' },
    { key: 'bread', text: 'Pain' },
    { key: 'cereal', text: 'Céréale' },
    { key: 'cheese', text: 'Fromage' },
    { key: 'chestnut', text: 'Chataigne' },
    { key: 'cinnamon', text: 'Canelle' },
    { key: 'coconut-milk', text: 'Lait de coco' },
    { key: 'coconut-milk-yoghurt', text: 'Yaourt au lait de coco' },
    { key: 'cream', text: 'Créme fraiche' },
    { key: 'flour', text: 'Farine' },
    { key: 'goat-cheese', text: 'Fromage de chèvre' },
    { key: 'goat-milk-yoghurt', text: 'Yaourt au lait de chèvre' },
    { key: 'hazelnut-oil', text: 'Huile de noisette' },
    { key: 'honey', text: 'Miel' },
    { key: 'kiri', text: 'Kiri' },
    { key: 'ladyfinger', text: 'Boudoir' },
    { key: 'olive-oil', text: "Huile d'olive" },
    { key: 'pasta', text: 'Pates' },
    { key: 'rapeseed-oil', text: 'Huile de colza' },
    { key: 'rice', text: 'Riz' },
    { key: 'rolled-oats', text: "Flocons d'avoine" },
    { key: 'rosemary', text: 'Romarin' },
    { key: 'salt', text: 'Sel' },
    { key: 'semolina', text: 'Semoule' },
    { key: 'sheep-cheese', text: 'Fromage de brebis' },
    { key: 'sheep-milk-yoghurt', text: 'Yaourt au lait de brebis' },
    { key: 'spelt', text: 'Epeautre' },
    { key: 'sugar', text: 'Sucre' },
    { key: 'sunflower-oil', text: 'Huile de tournesol' },
    { key: 'thyme', text: 'Thym' },
    { key: 'vache-qui-rit', text: 'Vache qui rit' },
    { key: 'vanilla', text: 'Vanille' },
    { key: 'verveina', text: 'Verveine' },
    { key: 'yogurt', text: 'Yaourt' }
];
