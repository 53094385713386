import VEGETABLES from './ingredients/vegetables';
import FRUITS from './ingredients/fruits';
import MEET from './ingredients/meat';
import OTHERS from './ingredients/others';
import sortByName from '../utils/food';

export const WINDOW_RESIZE = 'WINDOW_RESIZE';
export const INGREDIENTS = {
    ALL: [...VEGETABLES, ...FRUITS, ...MEET, ...OTHERS],
    VEGETABLES,
    FRUITS,
    MEET,
    OTHERS
};
export const MAPPED_INGREDIENTS = INGREDIENTS.ALL.reduce(
    (acc, ingredient) => ({
        ...acc,
        [ingredient.key]: ingredient
    }),
    {}
);
const UNSORTED_FRUITS_BY_MONTHS = FRUITS.reduce(
    (acc, ingredient) => {
        if (ingredient.months) {
            ingredient.months.forEach((month) => {
                acc[month].push(ingredient);
            });
        }
        return acc;
    },
    { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [], 12: [] }
);

export const FRUITS_BY_MONTHS = Object.keys(UNSORTED_FRUITS_BY_MONTHS).reduce(
    (acc, key) => ({ ...acc, [key]: UNSORTED_FRUITS_BY_MONTHS[key].sort(sortByName) }),
    {}
);
const UNSORTED_VEGETABLES_BY_MONTHS = VEGETABLES.reduce(
    (acc, ingredient) => {
        if (ingredient.months) {
            ingredient.months.forEach((month) => {
                acc[month].push(ingredient);
            });
        }
        return acc;
    },
    { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [], 12: [] }
);
export const VEGETABLES_BY_MONTHS = Object.keys(UNSORTED_VEGETABLES_BY_MONTHS).reduce(
    (acc, key) => ({ ...acc, [key]: UNSORTED_VEGETABLES_BY_MONTHS[key].sort(sortByName) }),
    {}
);

export const MONTHS_MAP = {
    1: 'Janvier',
    2: 'Février',
    3: 'Mars',
    4: 'Avril',
    5: 'Mai',
    6: 'Juin',
    7: 'Juillet',
    8: 'Aout',
    9: 'Septembre',
    10: 'Octobre',
    11: 'Novembre',
    12: 'Décembre'
};

export const LUNCH_TIMES = {
    breakfast: 'breakfast',
    lunch: 'lunch',
    teatime: 'teatime',
    dinner: 'dinner'
};

export const LUNCH_ORDERS = {
    breakfast: 0,
    lunch: 1,
    teatime: 2,
    dinner: 3
};
