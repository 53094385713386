import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import KeyIcon from '@material-ui/icons/VpnKey';
import ExitIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/Info';
import ClearIcon from '@material-ui/icons/Clear';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import HomeIcon from '@material-ui/icons/Home';
import CalendarIcon from '@material-ui/icons/DateRange';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import { getAuthIsLoggedIn } from '../../selectors/auth';
import { signOut } from '../../actions/auth';
import InformationModal from '../InformationModal/InformationModal';
import { getAppWidth } from '../../selectors/app';
import MenuIcon from '@material-ui/icons/Menu';
import ListIcon from '@material-ui/icons/List';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

function Header(props) {
    const [isInformationModalOpen, setIsInformationModalOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    function navigateTo(path) {
        const { history } = props;
        history.push(path);
        setIsDrawerOpen(false);
    }

    function handleLogOut() {
        const { logOut } = props;
        logOut();
        setIsDrawerOpen(false);
    }

    return (
        <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                    {props.isLoggedIn && (
                        <IconButton
                            aria-label="menu"
                            onClick={() => setIsDrawerOpen(true)}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography style={{ width: '1em' }} />
                    <Typography variant="h6" onClick={() => navigateTo('/')} className="clickable">
                        Les repas{props.appWidth >= 500 ? ' de Lucas' : ''}
                    </Typography>
                    <Typography style={{ flexGrow: 1 }} />
                    {!props.isLoggedIn && (
                        <React.Fragment>
                            <IconButton
                                aria-label="login"
                                onClick={() => navigateTo('/sign-in')}
                                color="inherit"
                            >
                                <KeyIcon />
                            </IconButton>
                        </React.Fragment>
                    )}
                    {props.isLoggedIn && (
                        <React.Fragment>
                            <IconButton aria-label="login" onClick={handleLogOut} color="inherit">
                                <ExitIcon />
                            </IconButton>
                        </React.Fragment>
                    )}
                    <IconButton
                        aria-label="application informations"
                        onClick={() => setIsInformationModalOpen(true)}
                        color="inherit"
                    >
                        <InfoIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                <List>
                    <ListItem button onClick={() => setIsDrawerOpen(false)}>
                        <ListItemIcon>
                            <ClearIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Les repas de Lucas"
                            primaryTypographyProps={{ variant: 'h6' }}
                        />
                    </ListItem>
                    <ListItem button onClick={() => navigateTo('/')}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Accueil" />
                    </ListItem>
                    <ListItem button onClick={() => navigateTo('/tried')}>
                        <ListItemIcon>
                            <RestaurantIcon />
                        </ListItemIcon>
                        <ListItemText primary="Aliments essayés" />
                    </ListItem>
                    <ListItem button onClick={() => navigateTo('/history')}>
                        <ListItemIcon>
                            <CalendarViewDayIcon />
                        </ListItemIcon>
                        <ListItemText primary="Historique" />
                    </ListItem>
                    <ListItem button onClick={() => navigateTo('/food-list')}>
                        <ListItemIcon>
                            <ListIcon />
                        </ListItemIcon>
                        <ListItemText primary="Aliments" />
                    </ListItem>
                    <ListItem button onClick={() => navigateTo('/food-calendar')}>
                        <ListItemIcon>
                            <CalendarIcon />
                        </ListItemIcon>
                        <ListItemText primary="Calendrier des aliments" />
                    </ListItem>
                </List>
            </Drawer>
            <InformationModal
                open={isInformationModalOpen}
                onClose={() => setIsInformationModalOpen(false)}
            />
        </React.Fragment>
    );
}

Header.propTypes = {
    logOut: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    appWidth: PropTypes.number.isRequired
};

const mapStateToProps = (store) => ({
    isLoggedIn: getAuthIsLoggedIn(store),
    isLoading: getAuthIsLoggedIn(store),
    appWidth: getAppWidth(store)
});

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch(signOut())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
